import Auth from '@aws-amplify/auth';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import React from 'react';

export default function AccountMenu({ authData }: { authData: any }) {
  function signOut() {
    Auth.signOut();
  }

  function currentUserEmail() {
    if (!authData || !authData.signInUserSession) {
      return;
    }

    return authData.signInUserSession.idToken.payload.email;
  }

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement | null>(
    null,
  );
  const menuOpen = menuAnchorEl !== null;

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={evt => setMenuAnchorEl(evt.currentTarget)}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={menuOpen}
        onClose={() => setMenuAnchorEl(null)}
      >
        <MenuItem disabled>{currentUserEmail()}</MenuItem>
        <MenuItem onClick={signOut}>Log Out</MenuItem>
      </Menu>
    </div>
  );
}
