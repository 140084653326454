import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { HubCapsule } from '@aws-amplify/core/lib-esm/Hub';
import { Button, IconButton, makeStyles } from '@material-ui/core';
import { indigo } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import { SnackbarProvider, WithSnackbarProps } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Loading from './Loading';
import NavBar from './NavBar';
import AppRouter from './Router';
import { AuthState } from './types';

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  loggedInText: {
    color: indigo[200],
  },
  signInWrapper: {
    padding: '50px 0',
    textAlign: 'center',
  },
  snackbarClose: {
    padding: theme.spacing(0.5),
  },
}));

export default function App() {
  const [authState, setAuthState] = useState<AuthState>({ state: 'loading' });
  const snackbarRef = useRef<WithSnackbarProps>();

  useEffect(() => {
    // Load initial login state unless there's a "code" in the URL, which
    // indicates we're processing a callback from the hosted UI
    function getLoginState() {
      Auth.currentAuthenticatedUser()
        .then(user => {
          setAuthState({ state: 'signedIn', data: user });
        })
        .catch(e => {
          setAuthState({ state: 'signIn' });
        });
    }

    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has('code')) {
      getLoginState();
    }

    // Set up a handler for auth state changes
    function handleAuthEvent(data: HubCapsule) {
      switch (data.payload.event) {
        case 'signIn':
          getLoginState();
          break;
        case 'signIn_failure':
          setAuthState({
            state: 'signIn',
            data: undefined,
            error: data.payload.data,
          });
          break;
        default:
          console.log(data.payload, 'Unhandled auth event');
          break;
      }
    }

    Hub.listen('auth', handleAuthEvent);

    return () => Hub.remove('auth', handleAuthEvent);
  }, []);

  function handleSnackbarClose(key: string | number | undefined) {
    snackbarRef.current!.closeSnackbar(key);
  }

  const classes = useStyles();

  return (
    <div style={{ height: '100%' }}>
      <Router>
        <SnackbarProvider
          ref={snackbarRef}
          action={key => (
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              className={classes.snackbarClose}
              onClick={() => handleSnackbarClose(key)}
            >
              <CloseIcon />
            </IconButton>
          )}
        >
          {authState.state === 'loading' && <Loading />}
          {authState.state === 'signIn' && (
            <>
              <NavBar authState={authState} linkToHome={false} />
              <div className={classes.signInWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => Auth.federatedSignIn()}
                >
                  Log In Or Register
                </Button>
              </div>
            </>
          )}
          {authState.state === 'signedIn' && (
            <AppRouter authState={authState} />
          )}
        </SnackbarProvider>
      </Router>
    </div>
  );
}
