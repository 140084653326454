import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NavBar from './NavBar';
import NoteEditor from './NoteEditor';
import NoteList from './NoteList';
import { AuthState } from './types';

export default function AppRouter({ authState }: { authState: AuthState }) {
  return (
    <>
      <NavBar authState={authState} />
      <Switch>
        <Route path="/notes/:id">
          <NoteEditor />
        </Route>
        <Route path="/">
          <NoteList />
        </Route>
      </Switch>
    </>
  );
}
