import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import AccountMenu from './AccountMenu';
import { AuthState } from './types';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default function NavBar({
  authState,
  linkToHome = true,
}: {
  authState: AuthState;
  linkToHome?: boolean;
}) {
  const classes = useStyles();
  const location = useLocation();

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          {linkToHome && location.pathname !== '/' ? (
            <Link to="/" className={classes.link}>
              Notes
            </Link>
          ) : (
            'Notes'
          )}
        </Typography>
        {authState.state === 'signedIn' && (
          <AccountMenu authData={authState.data} />
        )}
      </Toolbar>
    </AppBar>
  );
}
