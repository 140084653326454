import { CircularProgress, Fade, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    margin: '20px 0',
  },
}));

export default function Loading() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Fade
        in
        style={{
          transitionDelay: '800ms',
        }}
        unmountOnExit
      >
        <CircularProgress />
      </Fade>
    </div>
  );
}
