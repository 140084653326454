import {
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { Prompt, Redirect, useParams } from 'react-router-dom';

import sendAPIRequest from '../util/sendAPIRequest';

import LoadError from './LoadError';
import Loading from './Loading';
import { Note } from './types';

const useStyles = makeStyles({
  card: {
    maxWidth: 800,
    margin: '20px auto',
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    border: 'none',
    outline: 'none',
    fontSize: 30,
  },
  text: {
    flex: 1,

    border: 'none',
    outline: 'none',
    resize: 'none',
  },
  actions: {
    paddingTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
});

async function loadNote(
  id: string,
): Promise<{ newState: 'loaded' | 'loadError'; note?: Note }> {
  try {
    return {
      note: await sendAPIRequest<Note>(`/notes/${id}`),
      newState: 'loaded',
    };
  } catch (e) {
    console.error('Error loading note', e);
    return { newState: 'loadError' };
  }
}

export default function NoteEditor() {
  let { id } = useParams<{ id: string }>();
  let [note, setNote] = useState<Note | undefined>();
  let [state, setState] = useState<
    'loading' | 'loadError' | 'loaded' | 'edited' | 'saving' | 'done'
  >('loading');
  const [snackbarKey, setSnackbarKey] = useState<any>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();

  const titleInput = useRef<HTMLInputElement>(null);
  const textInput = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    (async () => {
      const { note, newState } = await loadNote(id);
      setState(newState);
      setNote(note);
    })();
  }, [id]);

  async function onRetry() {
    setState('loading');

    const { note, newState } = await loadNote(id);
    setState(newState);
    setNote(note);
  }

  function onChange() {
    setState('edited');
    setNote(n => ({
      ...n!,
      name: titleInput.current!.value,
      text: textInput.current!.value,
    }));
  }

  async function onSave() {
    setState('saving');
    closeSnackbar(snackbarKey);

    try {
      await sendAPIRequest(`/notes/${id}`, {
        method: 'PUT',
        body: JSON.stringify({
          name: note!.name,
          text: note!.text,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setState('done');
    } catch (e) {
      console.error('Error saving note', e);

      setSnackbarKey(
        enqueueSnackbar('Error saving note', {
          variant: 'error',
        }),
      );
      setState('edited');
    }
  }

  async function onDelete() {
    if (
      !window.confirm(
        'Are you sure you want to delete this note? This action cannot be undone.',
      )
    ) {
      return;
    }

    setState('saving');
    closeSnackbar(snackbarKey);

    try {
      await sendAPIRequest(`/notes/${id}`, {
        method: 'DELETE',
      });

      setState('done');
    } catch (e) {
      console.error('Error deleting note', e);

      setSnackbarKey(
        enqueueSnackbar('Error deleting note', {
          variant: 'error',
        }),
      );
      setState('edited');
    }
  }

  return (
    <>
      <Prompt
        when={state === 'edited'}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          {note && (
            <>
              <input
                disabled={state === 'saving'}
                className={classes.title}
                type="text"
                value={note.name}
                onChange={onChange}
                ref={titleInput}
              />
              <textarea
                disabled={state === 'saving'}
                className={classes.text}
                value={note.text}
                onChange={onChange}
                ref={textInput}
              />
            </>
          )}
          {state === 'loading' && <Loading />}
          <LoadError
            msg={state === 'loadError' ? 'Failed to load note' : undefined}
            onRetry={onRetry}
          />
        </CardContent>
        <CardActions className={classes.actions}>
          {state === 'loaded' && (
            <Button onClick={() => setState('done')}>Close</Button>
          )}
          {state === 'edited' && <Button onClick={onSave}>Save & Close</Button>}
          {(state === 'loaded' || state === 'edited') && (
            <Button color="secondary" onClick={onDelete}>
              Delete
            </Button>
          )}
          {state === 'saving' && <Loading />}
          {state === 'done' && <Redirect to="/" />}
        </CardActions>
      </Card>
    </>
  );
}
