import { Fab, makeStyles, Zoom } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Redirect } from 'react-router';

import sendAPIRequest from '../util/sendAPIRequest';

import Loading from './Loading';
import { Note } from './types';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'fixed',
    bottom: 30,
    right: 30,
    width: 56,
    height: 56,
  },
}));

export default function NewNoteButton() {
  const classes = useStyles();
  const [state, setState] = useState<'idle' | 'creating' | 'created'>('idle');
  const [newNoteId, setNewNoteId] = useState<number | undefined>();
  const [snackbarKey, setSnackbarKey] = useState<any>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  async function newNote() {
    setState('creating');
    closeSnackbar(snackbarKey);

    try {
      const note = await sendAPIRequest<Note>('/notes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: 'Untitled Note',
          text: '',
        }),
      });

      setNewNoteId(note.id);
      setState('created');
    } catch (e) {
      console.error('Error creating note', e);
      setSnackbarKey(
        enqueueSnackbar('Error creating note', {
          variant: 'error',
        }),
      );
      setState('idle');
    }
  }

  return (
    <div className={classes.wrapper}>
      <Zoom in={state === 'idle'} timeout={500} unmountOnExit>
        <Fab aria-label="New Note" color="primary" onClick={newNote}>
          <AddIcon />
        </Fab>
      </Zoom>
      {state === 'creating' && <Loading />}
      {state === 'created' && <Redirect to={`/notes/${newNoteId}`} />}
    </div>
  );
}
