import Auth from '@aws-amplify/auth';

import backendStack from '../backend-stack.json';

const apiBase = process.env.REACT_APP_API_BASE || backendStack.ServiceEndpoint;
console.log('Using API server: ' + apiBase);

export default async function sendAPIRequest<ResultType = any>(
  path: string,
  opts: RequestInit = {},
  retryCount = 5,
): Promise<ResultType> {
  const url = apiBase + path;
  const token = (await Auth.currentSession()).getIdToken();

  const response = await fetch(url, {
    ...opts,
    headers: {
      Authorization: token.getJwtToken(),
      ...(opts.headers || {}),
    },
  });

  if (response.status === 504) {
    // Gateway timeout -- probably a cold start from RDS serverless
    if (retryCount === 0) {
      throw new Error('Too many 504 errors');
    }

    return sendAPIRequest(path, opts, retryCount - 1);
  }

  if (response.status >= 400) {
    console.error('Error response', response);
    throw new Error(`Got status code ${response.status}`);
  }

  if (response.headers.get('Content-Type') === 'application/json') {
    return await response.json();
  }

  return (await response.text()) as any;
}
