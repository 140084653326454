import {
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import sendAPIRequest from '../util/sendAPIRequest';

import LoadError from './LoadError';
import Loading from './Loading';
import NewNoteButton from './NewNoteButton';
import { Note } from './types';

const useStyles = makeStyles(theme => ({
  card: {
    width: '300px',
    height: '200px',
    margin: '30px',
    float: 'left',
    '@media(max-width: 720px)': {
      width: 'calc(100% - 60px)',
    },
  },
  actionArea: {
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '&:last-child': {
      paddingBottom: '5px',
    },
  },
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  snippet: {
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
  },
  snippetText: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  fadeOut: {
    left: 0,
    right: 0,
    bottom: 0,
    height: '40px',
    position: 'absolute',
    backgroundImage: 'linear-gradient(top, rgba(255,255,255,0) 0%, #fff 100%)',
  },
  lastEdited: {
    fontSize: '12px',
    paddingTop: '15px',
    textAlign: 'right',
  },
  noNotes: {
    width: '100%',
    maxWidth: 400,
    padding: 30,
    margin: '30px auto',
  },
}));

export default function NoteList() {
  const [loadState, setLoadState] = useState<'loading' | 'error' | 'loaded'>(
    'loading',
  );
  const [notes, setNotes] = useState<Note[]>([]);

  async function loadNotes() {
    setLoadState('loading');

    try {
      const result = await sendAPIRequest<Note[]>('/notes');

      setNotes(result);
      setLoadState('loaded');
    } catch (e) {
      console.error('Error loading notes', e);
      setLoadState('error');
    }
  }

  useEffect(() => {
    loadNotes();
  }, []);

  const classes = useStyles();

  return (
    <>
      {loadState === 'loading' && <Loading />}
      <LoadError
        onRetry={loadNotes}
        msg={loadState === 'error' ? 'Error loading notes' : undefined}
      />
      {loadState === 'loaded' && (
        <div>
          {notes.map(item => (
            <Link to={`/notes/${item.id}`} key={item.id}>
              <Card className={classes.card}>
                <CardActionArea component="div" className={classes.actionArea}>
                  <CardContent className={classes.content}>
                    <Typography
                      variant="h5"
                      component="h2"
                      className={classes.title}
                    >
                      {item.name}
                    </Typography>
                    <div className={classes.snippet}>
                      <Typography className={classes.snippetText}>
                        {item.text}
                      </Typography>
                      <div className={classes.fadeOut}></div>
                    </div>

                    <Typography
                      color="textSecondary"
                      className={classes.lastEdited}
                    >
                      Last edited {new Date(item.updatedAt).toLocaleString()}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          ))}
          {notes.length === 0 && (
            <Card className={classes.noNotes}>
              <Typography>
                You don't have any notes. Use the button below to create one!
              </Typography>
            </Card>
          )}
          <NewNoteButton />
        </div>
      )}
    </>
  );
}
