import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
import React from 'react';
import ReactDOM from 'react-dom';

import backendStack from './backend-stack.json';
import App from './components/App';
import './fonts.css';
import './styles.css';

Amplify.configure({
  Auth: {
    identityPoolId: backendStack.CognitoIdentityPoolId,
    region: 'us-east-1',
    userPoolId: backendStack.UserPoolId,
    userPoolWebClientId: backendStack.UserPoolClientId,
    mandatorySignIn: false,
  },
});

Auth.configure({
  oauth: {
    domain: 'slsauth.benweissmann.com',
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: document.location.origin + '/',
    redirectSignOut: document.location.origin + '/',
    responseType: 'code',
  },
});

ReactDOM.render(<App />, document.getElementById('root'));
