import {
  Button,
  makeStyles,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  errorSnackbar: {
    marginTop: 70,
  },
  errorSnackbarContent: {
    backgroundColor: theme.palette.error.dark,
  },
}));

export default function LoadError({
  msg,
  onRetry,
}: {
  msg: undefined | string;
  onRetry: () => void;
}) {
  const classes = useStyles();

  return (
    <Snackbar
      className={classes.errorSnackbar}
      open={msg !== undefined}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <SnackbarContent
        className={classes.errorSnackbarContent}
        aria-describedby="client-snackbar"
        message={msg}
        action={
          <Button color="inherit" onClick={onRetry}>
            Retry
          </Button>
        }
      />
    </Snackbar>
  );
}
